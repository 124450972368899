.cke_bottom{
    display: none !important;
}
.cke_top{
    display: none !important;
}

.img-hover-zoom{
    transition: transform 0.5s;
}
.img-hover-zoom:hover{
    transform: scale(0.9);
}