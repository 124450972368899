
.hide-border-control {
    min-height: 50px !important;
    border: none !important;
}

.bg-tertiary {
    --x-bg-opacity: 1;
    background-color: rgba(255, 87, 154) !important;
}
.icon-shape {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    vertical-align: middle;
    border-radius: 0.375rem;
    width: 3rem;
    height: 3rem;
}

.text-lg {
    font-size: 1.25rem !important;
}