::placeholder{
    font-size: small!important;
    color: #bbbaba !important;
}
.nav-item .active-element {
    color: #ffffff !important;
    background-color:var(--theme-deafult);
}

.react-datepicker__month-dropdown-container{
    margin-right: 1.5rem !important;
}